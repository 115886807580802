<template lang="html" src="./index.template.vue"></template>

<style lang="scss" src="./index.scss"></style>

<script>
export default {
  name: 'PageIndex',
  created() {
    if (this.$route.params.supplierUUID) {
      this.$router.push({name: 'Dashboard', params: {supplierUUID: `${this.$route.params.supplierUUID}`}});
    } else if (this.$appConfig.userSuppliers) {
      this.$router.push({name: 'Dashboard', params: {supplierUUID: `${this.$appConfig.userSuppliers[0]._id}`}});
    }
  },
};
</script>
